module.exports = {
  default: {
    colors: {
      white: '#FFFFFF',
      black: '#333',
      main: '#333',
      grey: '#878787',
      lightgrey: '#E8E8E8',
      tablegrey: '#F3F3F3',
      beige: '#f5f5dc',
      loadingBar: '#2f80ed',
      blue: '#2f80ed',
      red: '#FF0000',
      background: '#f7f7f7',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      highlight: '#2f80ed',
      accent: '#eae6e1',
      lightAccent: '#f4f2f0',
      green: '#65706a'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem'
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: '"TT Commons W01",sans-serif',
      secondary: '"Ogg", serif'
    }
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};

// GRÖN 100% top + footer
// GRÖNA pilar när det är 20% på vitt

// PILARNA SAMMA den avlånga

// BEIGE ska vara 40% --> PIL OCH TEXT SVART

// HÖGRE HEADER --> VID SCROLL ENDAST SYMBOL OCH LÄGRE
// LÄGRE TOP BAR
