import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import Head from '@jetshop/core/components/Head';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t, { Intl } from '@jetshop/intl';
import { styled } from 'linaria/react';
import React from 'react';
import { Redirect } from 'react-router';
import BaseMaxWidth from '../../Layout/MaxWidth';
import LoadingPage from '../../LoadingPage';
import { smallSection } from '../UI/Form';
import Address from './Address';
import LoginFields from './LoginFields';
import { FormHeader } from '../LogInPage';

const MaxWidth = styled(BaseMaxWidth)`
  align-items: center;

  p {
    line-height: 1.5;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    max-width: 100%;
  }

  .hint {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
  }

  .section {
    &:before {
      opacity: 0;
    }
    margin-bottom: 2rem;
    transition: opacity 0.6s ease;
    opacity: 0.5;

    &.active {
      opacity: 1;
      &:before {
        opacity: 1;
      }
    }
  }
`;

function SignUpPage() {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();

  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  return (
    <SignupFormProvider LoadingComponent={<LoadingPage />}>
      <MaxWidth style={{ marginTop: '2em' }}>
        <Intl>{t => <Head data={{ title: t('Signup') }} />}</Intl>
        <FormHeader>
          <h1>{t('Sign up')}</h1>
        </FormHeader>
        <section className={smallSection}>
          <Address />
          <LoginFields />
        </section>
      </MaxWidth>
    </SignupFormProvider>
  );
}

export default SignUpPage;
