//import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import { theme } from '../../Theme';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import ChannelSelectorDropdown from './ChannelSelector/ChannelSelectorDropdown';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MobileMenu from './MobileMenu';
// import ChannelBanner from './RecommendedChannel/ChannelBanner';
import { Notifications } from '../Notifications';
import DynamicCategoryContent from '../../ui/DynamicCategoryContent';
// import Logo from '../../../img/logo.png';
import { ReactComponent as LogoBlack } from '../../../svg/LogoBlack.svg';
import { ReactComponent as LogoSymbol } from '../../../svg/LogoSymbol.svg';
import { SearchBar } from './Search/SearchBar';
import FavouritesFlyout from '../../ProductList/FavouritesFlyout';

import { ReactComponent as Search } from '../../../svg/Search.svg';
import { ReactComponent as Bars } from '../../../svg/Bars.svg';

import HeaderCampaign from './HeaderCampaign';
import useChannelCheck from '../../../utils/useChannelCheck';

const Container = styled('header')`
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  transition: all, 0.3s ease;

  a,
  a:visited {
    color: #333;
  }

  &.scrolled {
    top: 0;
    position: sticky;
    a.toplevel,
    a.inspo-link {
      padding: 15px 25px;
    }
  }

  ${theme.below.lg} {
    position: sticky;
    top: 0;
    z-index: 3;
  }
`;

export const MenuButton = styled('button')`
  border: 0;
  appearance: none;
  background: none;
  &:focus {
    outline: none;
  }
  svg {
    width: 22px !important;
    height: 25px !important;
    display: block;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  margin: 0;
`;

const HeaderWrapper = styled('div')`
  padding: 0;
  ${theme.below.lg} {
    padding: 7px 0;
  }

  &.scrolled {
    .logo-wrapper {
      svg {
        width: 25px;
      }
    }
  }
`;
const HeaderInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 0;
  margin: auto;
  ${theme.below.lg} {
    padding: 0 1rem;
  }
`;
const HeaderColumn = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  &.left {
    justify-content: flex-start;
    text-align: left;
  }
  &.center {
    justify-content: center;
    flex: none;
    text-align: center;
    ${theme.below.lg} {
      flex: none;
    }
  }
  &.right {
    justify-content: flex-end;
    text-align: right;
  }
`;
const LogoWrapper = styled('div')`
  svg {
    width: 65px;
    ${theme.below.lg} {
      width: 40px;
    }
  }
  a {
    display: block;
  }
`;
const SearchButton = styled('button')`
  background: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  margin-left: 10px;
  height: 25px;

  &:focus {
    outline: none;
  }

  svg {
    width: 22px !important;
    height: 25px !important;
  }

  ${theme.below.lg} {
    margin-left: 20px;
  }
`;

const TopBar = styled('div')`
  background: ${theme.colors.accent};
  position: relative;
`;
const TopBarInner = styled('div')`
  color: #333;
  font-size: 12px;
  font-weight: 600;
  max-width: 100%;
  margin: auto;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.below.lg} {
    padding: 3px 0;
  }

  .marquee-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    span {
      margin: 0 25vw;
    }
  }

  a,
  a:visited,
  button,
  li,
  span {
    color: #333 !important;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    ${theme.below.lg} {
      font-size: 12px;
    }
  }

  ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    li {
      &:after {
        content: '';
        margin-left: 10px;
        margin-right: 10px;
        ${theme.below.lg} {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      &:last-child:after {
        display: none;
      }
    }
  }
`;

const TopCenter = styled('div')`
  flex: none;
  width: 100%;
  ${theme.below.lg} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const TopRight = styled('div')`
  flex: 1;
  justify-content: flex-end;
  text-align: right;
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  z-index: 5;
  background: ${theme.colors.accent};

  > div {
    display: flex;
    align-items: center;
  }
`;
const InspirationLink = styled(Link)`
  padding: 40px 25px;
  display: inline-block;
  color: #333;
  text-decoration: none;
  position: relative;
  letter-spacing: 1px;

  &:after {
    content: '';
    background: black;
    position: absolute;
    bottom: 15px;
    left: 25px;
    right: 25px;
    transition: all, 0.2s ease;
    height: 0;
  }

  &:hover {
    &:after {
      height: 3px;
    }
  }
  &.active {
    &:after {
      height: 3px;
    }
  }
`;

// function CountDown({ date, fallback }) {
//   const [days, setDays] = useState(0);
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const [timeUp, setTimeUp] = useState(false);

//   function calcDifference() {
//     let eventDate = +new Date(date);
//     let difference = eventDate - +new Date();
//     if (difference < 1) {
//       setTimeUp(true);
//     } else {
//       setDays(Math.floor(difference / (1000 * 60 * 60 * 24)));
//       setHours(Math.floor((difference / (1000 * 60 * 60)) % 24));
//       setMinutes(Math.floor((difference / (1000 * 60)) % 60));
//       setSeconds(Math.floor((difference / 1000) % 60));
//     }
//   }

//   useEffect(() => {
//     calcDifference();
//     const interval = setInterval(() => {
//       calcDifference();
//     }, 1000);
//     return () => {
//       clearInterval(interval);
//     };
//   }, []);
//   if (days > 0) return fallback;
//   if (timeUp) return fallback;
//   return (
//     <div>
//       Vi fyller 1 år ✨ Du får 30% rabatt på alla posters, endast idag!&nbsp;
//       {hours > 9 ? hours : `0${hours}`}:{minutes > 9 ? minutes : `0${minutes}`}:
//       {seconds > 9 ? seconds : `0${seconds}`}
//     </div>
//   );
// }

function TopHeader({ categories, scrolled }) {
  const [searchOpen, setSearchOpen] = React.useState(false);

  const rightCategoriesList = [150, 151, 152];
  const rightCategories = categories?.data?.categories?.filter(category =>
    rightCategoriesList.includes(category.id)
  );
  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <HeaderWrapper className={scrolled ? 'scrolled' : 'not-scrolled'}>
                <HeaderInner>
                  <HeaderColumn className="left">
                    <CategoryMenu data={categories.data} />
                  </HeaderColumn>

                  <HeaderColumn className="center">
                    <LogoWrapper className="logo-wrapper">
                      <Link to="/">
                        {scrolled ? <LogoSymbol /> : <LogoBlack />}
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>

                  <HeaderColumn className="right">
                    {rightCategories?.map((category, index) => {
                      return (
                        <InspirationLink
                          key={index}
                          className="inspo-link"
                          to={category?.primaryRoute?.path}
                        >
                          {category?.name}
                        </InspirationLink>
                      );
                    })}
                    {searchOpen ? <SearchBar searchOpen={searchOpen} /> : null}
                    <SearchButton onClick={() => setSearchOpen(!searchOpen)}>
                      <IconContainer>
                        <Search />
                      </IconContainer>
                    </SearchButton>
                    <FavouriteCount />
                    <CartButton />
                  </HeaderColumn>
                </HeaderInner>
                <HeaderCampaign />
              </HeaderWrapper>
            </>
          ) : (
            <>
              <HeaderWrapper>
                <HeaderInner>
                  <HeaderColumn className="left">
                    <DrawerTrigger preventOverflow={true} id="menu-drawer">
                      {drawer => (
                        <MenuButton
                          onClick={
                            drawer.isOpen
                              ? drawer.hideTarget
                              : drawer.showTarget
                          }
                        >
                          <IconContainer>
                            <Bars />
                          </IconContainer>
                        </MenuButton>
                      )}
                    </DrawerTrigger>
                    <SearchButton onClick={() => setSearchOpen(!searchOpen)}>
                      <IconContainer>
                        <Search />
                      </IconContainer>
                    </SearchButton>
                  </HeaderColumn>

                  <HeaderColumn className="center">
                    <LogoWrapper>
                      <Link to="/">
                        <LogoBlack />
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>

                  <HeaderColumn className="right">
                    <FavouriteCount />
                    <CartButton />
                  </HeaderColumn>
                </HeaderInner>
                {searchOpen ? <SearchBar searchOpen={searchOpen} /> : null}
              </HeaderWrapper>
              <HeaderCampaign />
            </>
          )
        }
      </Above>
    </>
  );
}

export default function Header() {
  const { isB2B } = useChannelCheck();
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (window.pageYOffset >= 135) {
      setSticky(true);
    }
    if (window.pageYOffset === 0) {
      setSticky(false);
    }
  };

  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const mobileResult = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 4
    }
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      {/* <ChannelBanner /> */}
      <TopBar>
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <TopBarInner>
                <TopCenter>
                  <DynamicCategoryContent
                    marquee={true}
                    id={isB2B ? 325 : 186}
                  />
                </TopCenter>
                <TopRight>{!isB2B && <ChannelSelectorDropdown />}</TopRight>
              </TopBarInner>
            ) : (
              <TopBarInner>
                <TopCenter>
                  <DynamicCategoryContent
                    marquee={true}
                    id={isB2B ? 325 : 186}
                  />
                </TopCenter>
              </TopBarInner>
            )
          }
        </Above>
      </TopBar>
      <Container className={isSticky ? 'scrolled' : 'not-scrolled '} ref={ref}>
        <TopHeader categories={result} scrolled={isSticky ? true : false} />
      </Container>
      <Below breakpoint="lg">
        <MobileMenu data={mobileResult.data} />
      </Below>
      <CartFlyout />
      <FavouritesFlyout />
      <Notifications />
    </>
  );
}
