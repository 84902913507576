import { styled } from 'linaria/react';
import React from 'react';
import { ReactComponent as Instagram } from '../../../svg/Instagram.svg';
import { theme } from '../../Theme';

const Wrapper = styled('div')`
  margin-right: 0;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
  }

  li {
    margin-right: 10px;
  }

  a.social-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  color: ${theme.colors.black};
`;

const LogoWrapper = styled('span')`
  background: ${theme.colors.black};
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg {
    width: 100%;
  }
`;

const SocialLinks = ({ showLabel = true }) => (
  <Wrapper>
    <ul>
      {/* <li>
        <a className="social-links" href="/">
          <LogoWrapper>
            <Facebook />
          </LogoWrapper>
          {showLabel && <label>Facebook</label>}
        </a>
      </li> */}
      <li>
        <a
          className="social-links"
          href="https://www.instagram.com/designedposters/"
          target="_blank"
          rel="noreferrer"
        >
          <LogoWrapper>
            <Instagram />
          </LogoWrapper>
          {showLabel && <label>Instagram</label>}
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
