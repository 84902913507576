import useAddressFields from '@jetshop/core/components/Auth/useAddressFields';
import t from '@jetshop/intl';
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput';
import { css } from 'linaria';
import React from 'react';
import Input, { Label } from '../../Forms/Input';
import { PID } from './PID';
import { CustomerType } from './CustomerType';
import { SmallCaps } from '../../ui/Headings';

const sectionStyle = css`
  margin-top: 2em;
`;

export default function Address(props) {
  const { fields, setCountryByCode, countries } = useAddressFields();

  return (
    <section className={sectionStyle}>
      <SmallCaps className="heading">{t('Contact Details')}</SmallCaps>

      <CustomerType />

      <div style={{ marginBottom: '2em' }}>
        <Label htmlFor="country">{t('Country')}</Label>
        <CountriesInput
          name="country"
          onChange={e => setCountryByCode(e.currentTarget.value)}
          countries={countries}
        />
      </div>

      <PID />

      {fields.map(field => {
        // Map over billingAddressFields and display them
        return <Input {...field.inputProps} />;
      })}
    </section>
  );
}
