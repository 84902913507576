import Marquee from 'react-fast-marquee';
import { useQuery } from '@apollo/react-hooks';
// import { styled } from 'linaria/react';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import React from 'react';
import { useLocation } from 'react-router';
import dynamicCategoryContentQuery from './dynamicCategoryContentQuery.gql';

const StartpageText = ({ children }) => {
  const location = useLocation();
  if (location.pathname !== '/') return null;
  return children;
};

function getListItems(content) {
  const uspString = content;
  return uspString
    .replace(/<\/?[^>]+(>|$)/g, '')
    .trim()
    .split('\n');
}

const DynamicCategoryContent = ({ id, marquee }) => {
  const { data, loading, error } = useQuery(dynamicCategoryContentQuery, {
    variables: {
      id: id
    },
    errorPolicy: 'ignore'
  });
  if (loading || error) return null;
  return (
    <>
      {marquee && data?.category?.content ? (
        <div className="marquee-container">
          <Marquee speed={50}>
            {getListItems(data?.category?.content).map((usp, index) => {
              return <span key={index}>{usp}</span>;
            })}
          </Marquee>
        </div>
      ) : (
        <>
          {data?.category?.content && (
            <Shortcodes
              content={data?.category?.content}
              components={{
                StartpageText
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default DynamicCategoryContent;
