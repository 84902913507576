import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

export const CheckIndexing = () => {
  const { selectedChannel } = useContext(ChannelContext);
  if (selectedChannel.id === 3) {
    return (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  }
  return null;
};
