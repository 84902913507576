import React, { useState } from 'react';
import t from '@jetshop/intl';
import ChannelSelector, {
  SelectorBody,
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import flattenCountries from './flattenCountries';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../../../Theme';

const selectorStyle = css`
  border: 0;
  background: white;
`;

const Country = styled('div')`
  height: 35px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  background-color: transparent;
  color: #4f4f4f;
  font-weight: 400;
  gap: 0;
  justify-content: flex-end;

  ${theme.below.lg} {
    justify-content: flex-start;
  }

  &.active span,
  &:hover span {
    border-bottom: 1px solid black;
    padding-top: 1px;
  }
`;

const CountryFlag = styled('img')`
  height: auto;
  width: 16px;
  margin-left: 10px;
  margin-top: -2px;
`;

const StyledTitle = styled(Title)`
  color: #333;
  text-align: center;
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 14px;
  margin: -16px -16px 0;
  display: grid;
  place-items: center;
  height: 60px;
  border: 0;
  box-shadow: 0 -3px 10px -5px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin-bottom: 12px;
`;

const Selector = ({ channels, selectedChannel, updateChannel, hideTitle }) => {
  let [countries] = useState(flattenCountries(channels));
  countries = countries.map(country => ({
    ...country,
    title: `${country.name} / ${
      country.code === 'NO' ? 'NOK' : country.currencies[0].name
    }`
  }));

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({ selectedChannel, onSelect }) => (
        <MiniSelector className={selectorStyle}>
          <SelectorBody>
            {!hideTitle && (
              <StyledTitle>{t('Select your country')}</StyledTitle>
            )}
            {countries.map(({ title, name, code, channel }) => {
              if (channel.name === 'b2b') {
                return null;
              }
              return (
                <Country
                  key={channel.id}
                  className={
                    channel.id === selectedChannel.id &&
                    name === selectedChannel.country.name
                      ? 'active'
                      : null
                  }
                  onClick={() => {
                    const chosenChannel = onSelect(
                      channel.id,
                      null,
                      code === 'NO' ? 'NOK' : null,
                      code
                    );
                    updateChannel(chosenChannel);
                  }}
                >
                  <span>{title}</span>
                  <CountryFlag
                    src={`https://countryflags.jetshop.io/${code}/flat/32.png`}
                    alt="Country Flag"
                  />
                </Country>
              );
            })}
          </SelectorBody>
        </MiniSelector>
      )}
    />
  );
};

export default Selector;
