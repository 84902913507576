import { theme } from '../../../Theme';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useState, useRef, useEffect } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import getCategoriesByLevel from './__util__/getCategoriesByLevel';
import getCategoriesUnderParent from './__util__/getCategoriesUnderParent';
import hasChildCategories from './__util__/hasChildCategories';
import { ReactComponent as AngleLeft } from '../../../../svg/AngleLeft.svg';
import { ReactComponent as AngleRight } from '../../../../svg/AngleRight.svg';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

const Icon = styled('div')`
  svg {
    width: 10px;
  }
`;

export const CloseButton = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  border-radius: 0;
  padding: 1rem;
  svg {
    display: block;
    width: 13px !important;
    height: 13px;
  }
  path {
    fill: black;
  }
`;

const Wrapper = styled('div')`
  &.toplvl {
    background: ${theme.colors.lightAccent};
    a {
      background: ${theme.colors.lightAccent};
    }
  }
  a {
    border-top: 1px solid #e0e0e0;
    display: block;
    padding: 1rem;
    position: relative;
    color: #333;
    text-decoration: none;
    font-weight: normal;
    font-size: 1rem;
    background: ${theme.colors.lightAccent};

    &.allbtn {
      font-weight: 600;
    }
    svg {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:last-child a {
    border-bottom: 1px solid #e0e0e0;
  }
  .non-clickable {
    pointer-events: none;
  }
`;

const SubcategoryWrapper = styled('div')`
  background: ${theme.colors.lightAccent};
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  min-height: 150vh;
  max-height: 100vh;
  &.open {
    transform: translateX(0);
    opacity: 1;
    max-height: 9999px;
    bottom: auto;
    padding-bottom: 85px;
  }
`;

const SubNavHeader = styled('header')`
  background: ${theme.colors.accent};
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 50px;
`;

const BackButton = styled('button')`
  background: none;
  padding: 1rem;
  &:focus {
    outline: none;
  }
  font-size: 1rem;
  path {
    fill: black;
  }
`;

const CategoryTitle = styled('span')`
  font-size: 1rem;
  font-weight: 600;
`;

const MobileCategories = ({
  categories,
  closeMenu,
  parentCategory = null,
  menuWrapper,
  setLocked,
  openCat,
  setOpenCat
}) => {
  const thisSubMenu = useRef(null);
  const [openIndex, setOpenIndex] = useState(null);
  const categoryLevel = parentCategory
    ? [parentCategory]
    : getCategoriesByLevel(categories, 1);

  useEffect(
    v => {
      if (thisSubMenu.current && thisSubMenu.current.scrollHeight) {
        setLocked(
          thisSubMenu.current.scrollHeight < menuWrapper.current.scrollHeight
        );
      } else {
        setLocked(false);
      }
      if (openCat === null) {
        setOpenIndex(null);
      }
    },
    [openCat, menuWrapper, setLocked]
  );

  return (
    <>
      {categoryLevel.map((category, i) => (
        <React.Fragment key={i}>
          <Wrapper
            className={`${!parentCategory ? 'toplvl' : 'sublvl'} `}
            key={i}
          >
            <CategoryLink
              className={`${!parentCategory ? 'toplvl' : 'sublvl'}`}
              onClick={e => {
                if (
                  hasChildCategories(categories, category) &&
                  category.id !== 151 &&
                  category.id !== 152
                ) {
                  handleClickAndPreventDefault(
                    e,
                    () => {
                      setOpenCat(category.id);
                      setOpenIndex(i);
                    },
                    i
                  );
                } else {
                  closeMenu();
                }
              }}
              category={category}
            >
              {category.name}
              {hasChildCategories(categories, category) &&
                category.id !== 151 &&
                category.id !== 152 && (
                  <Icon>
                    <AngleRight />
                  </Icon>
                )}
            </CategoryLink>

            <SubcategoryWrapper
              className={openIndex === i ? 'open' : null}
              innerRef={openCat === category.id ? thisSubMenu : null}
            >
              <SubNavHeader>
                <BackButton
                  onClick={() => {
                    setOpenCat(category.parentId);
                    setOpenIndex(category.parentId);
                    setLocked(false);
                  }}
                >
                  <Icon>
                    <AngleLeft />
                  </Icon>
                </BackButton>
                <CategoryTitle>{category.name}</CategoryTitle>
                <CloseButton
                  className="close-btn"
                  onClick={() => {
                    closeMenu();
                    setOpenCat(null);
                  }}
                >
                  <Icon>
                    <Cross />
                  </Icon>
                </CloseButton>
              </SubNavHeader>
              {category.parentId !== 149 && (
                <Wrapper>
                  <CategoryLink
                    onClick={() => {
                      closeMenu();
                      setOpenCat(null);
                    }}
                    className={'allbtn sublvl'}
                    category={category}
                  >
                    {t('See all {category.name}', { category })}
                  </CategoryLink>
                </Wrapper>
              )}
              <SubCategories
                setLocked={setLocked}
                menuWrapper={menuWrapper}
                categories={categories}
                category={category}
                closeMenu={closeMenu}
                setOpenCat={setOpenCat}
                openCat={openCat}
              />
            </SubcategoryWrapper>
          </Wrapper>
        </React.Fragment>
      ))}
    </>
  );
};

const SubCategories = ({
  categories,
  category,
  closeMenu,
  menuWrapper,
  setLocked,
  setOpenCat,
  openCat
}) => {
  return getCategoriesUnderParent(categories, category.id).map(
    (childCat, index) => {
      return hasChildCategories(categories, childCat) ? (
        <MobileCategories
          setLocked={setLocked}
          menuWrapper={menuWrapper}
          key={index}
          categories={categories}
          closeMenu={closeMenu}
          parentCategory={childCat}
          setOpenCat={setOpenCat}
          openCat={openCat}
        />
      ) : (
        <CategoryLink
          className="sublvl"
          key={index}
          onClick={() => {
            closeMenu();
            setOpenCat(null);
          }}
          category={childCat}
        >
          {childCat.name}
        </CategoryLink>
      );
    }
  );
};

export default MobileCategories;
