import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      @import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=a135aa25-294c-4739-9c02-824e0ebc092b');
      @font-face {
        font-family: 'Ogg';
        src: url('./Ogg-Regular.woff2') format('woff2'),
          url('./Ogg-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'TT Commons W01';
        src: url('./a39250af-b691-475a-86d2-6b133809e50f.woff2') format('woff2'),
          url('./0c7cc6a8-afe0-4037-8e0c-f9de2e5d766c.woff') format('woff');
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: 'TT Commons W01';
        src: url('./56184c9a-0155-4961-a836-516c2a37949d.woff2') format('woff2'),
          url('./941bd4ef-6d96-4cc3-b891-b967fb693919.woff') format('woff');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'TT Commons W01';
        src: url('./c7e2cf7d-6add-43b2-8bd9-f68faa219cb6.woff2') format('woff2'),
          url('./9a718670-1649-4925-a033-3a8b409e3a39.woff') format('woff');
        font-weight: 700;
        font-style: normal;
      }
    }
  `;
}
