import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import React from 'react';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';
import cartQuery from '../Cart/CartQuery.gql';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import Button from '../ui/Button';
import { Product } from './FlyoutProduct';
import { useNotification } from '@jetshop/core/components/Notifications';

const Flyout = styled('div')`
  background: white;
  color: ${theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;

  > div {
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
  }
  > section {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
  }
`;

const FavouritesFlyoutView = ({ result, modal, ...rest }) => {
  const { products, loading } = useProductListItems();
  const [trigger] = useNotification();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            <Cart />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <Flyout {...rest}>
        <MaxWidth
          className={cx(container, ' empty ', loading ? 'loading' : null)}
        >
          <h2>{t('Saved items')}</h2>
          <p>{t('There are no products in your saved items list.')}</p>
        </MaxWidth>
      </Flyout>
    );
  }

  if (loadingInitialRender) {
    return (
      <Flyout {...rest}>
        <MaxWidth
          className={cx(container, ' empty ', loading ? 'loading' : null)}
        >
          <h2>{t('Saved items')}</h2>
          <p>{t('Loading your saved items…')}</p>
        </MaxWidth>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <ul className="product-grid">
          {products.map((product, index) => {
            return (
              <li
                key={product?.variant?.articleNumber || product.articleNumber}
                data-valid={product.validation.status}
              >
                <Product product={product} />
              </li>
            );
          })}
        </ul>

        <div className="add-clear">
          <Button
            style={{ marginTop: '2em' }}
            onClick={addToCart}
            loading={mutationLoading}
            className="add-all"
            disabled={validItemCount === 0}
          >
            {validItemCount > 0
              ? mutationLoading
                ? t('One moment…')
                : t(
                    '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                    { productCount: validItemCount }
                  )
              : t('No valid items to add to cart')}
          </Button>
          <ClearList>{t('Clear list')}</ClearList>
        </div>
      </MaxWidth>
    </Flyout>
  );
};

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children }) {
  const { clear } = useProductList();
  return (
    <Button secondary onClick={clear}>
      {children}
    </Button>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}

const FavouritesFlyout = props => (
  <DrawerTarget id="favourites-drawer">
    {drawer => (
      <Drawer isOpen={drawer.isOpen} right>
        <FavouritesFlyoutView modal={drawer} {...props} />
      </Drawer>
    )}
  </DrawerTarget>
);

const container = css`
  margin-top: 2em;
  &.loading {
    opacity: 0.6;
  }
  &.empty {
    padding: 2rem;
  }

  h1 {
    margin-bottom: 1em;
    font-weight: 600;
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;

    li {
      width: 100%;
    }
  }

  .product-card {
    margin-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 10px 20px 10px;
    a {
      margin: 0;
      border: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .remove-from-list {
      padding: 0;
      font-size: 13px;
      height: 30px;
      width: 30px;
    }

    .image-wrapper {
      width: 80px;
      padding: unset;
    }
    .product-card-detail {
      flex: 1;
      text-align: left;

      span,
      h3 {
        padding: 0;
        white-space: normal;
      }
      .price-package-wrapper {
        justify-content: flex-start;
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      > * {
        flex: 1;
        margin-top: 0;
      }
      .select-variant {
        padding: 0;
        margin-right: 5px;

        > div > button {
          padding: 0;
          border-radius: 0;
          height: 30px;
          font-size: 11px;
          background: white;
          border: 1px solid black;
          text-transform: uppercase;
          &:focus {
            outline: none;
            border-radius: 0;
          }

          + ul {
            border: 1px solid #c0c0c0;
            border-top: 0;
            > li {
              font-size: 11px;
              &:focus,
              &:hover {
                outline: none;
                color: #333;
                background: #efefef;
              }
            }
          }
        }
      }
      .invalid-product,
      > button {
        padding: 0;
        text-align: center;
        background: ${theme.colors.green};
        font-weight: normal;
        color: white;
        border: 0;
        height: 30px;
        line-height: 30px;
        margin-left: 5px;
        font-size: 11px;
        text-transform: uppercase;
        &.add-to-cart-button {
          background: #048a01;
          color: white;
        }
      }
    }
  }

  .product-card-image {
    div {
      display: none;
    }
  }

  .product-card-detail,
  .product-card,
  .product-card a {
    background: white;
  }

  .select-variant {
    margin: 0;
    padding: 1em;
    position: relative;
    [data-flight-dropdown] {
      + [data-flight-dropdown] {
        margin-top: 0.5em;
      }
      button,
      ul,
      li {
        width: 100%;
      }
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .add-clear {
    padding: 10px;
  }
  .add-all {
    background: ${theme.colors.green};
    color: white;
  }
  .add-clear button {
    width: 100%;
    display: block;
    height: 35px;
    padding: 0;
    line-height: 35px;
    margin-bottom: 10px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .new-price,
  .old-price {
    display: inline-block;
  }
  .old-price {
    margin-left: 0.5em;
  }
`;

const addAllToastStyles = css`
  color: white;
  background: ${theme.colors.green};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;

export default FavouritesFlyout;
