import React from 'react';
import pagesQuery from './PagesQuery.gql';
import ContentPageLink from '@jetshop/ui/ContentPageLink';
import { useQuery } from '@apollo/react-hooks';

function FooterLinks() {
  const { data } = useQuery(pagesQuery);

  const pages = data?.pages;

  return pages ? (
    <section>
      <ul>
        {pages.map(page => {
          if (!page.primaryRoute || page.id === 55) return null;
          return (
            <li key={page.id}>
              <ContentPageLink page={page}>{page.name}</ContentPageLink>
            </li>
          );
        })}
      </ul>
    </section>
  ) : null;
}

export default FooterLinks;
