import { Favourite } from '../ProductList/Favourite';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
// import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React, { useState, useEffect, useContext } from 'react';
import { theme } from '../Theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import useChannelCheck from '../../utils/useChannelCheck';
//import { ReactComponent as NewsSE } from '../../svg/news_se.svg';
// import { ReactComponent as NewsENG } from '../../svg/news_eng.svg';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    > div {
    }
  }

  .image-wrapper {
    background-color: ${theme.colors.lightAccent};
    padding: 20%;
    > div {
      box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
    }

    &.landscape {
      padding: 30% 5% 30% 5%;
    }

    &.environment-image {
      padding: 0;
      > div {
        box-shadow: none;
      }
    }
    &.b2b,
    &.canvas {
      padding: 0;
      > div {
        box-shadow: none;
      }
    }
  }

  .product-card-detail {
    padding: 0.75em;
    line-height: 1.35;
    background: none;
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.03em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
    }

    h4 {
      font-weight: normal;
      font-size: 1em;
      color: ${theme.colors.grey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .package-badge {
    display: none;
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        margin: 0 3px;

        &.old-price {
          opacity: 0.4;
        }
      }
    }

    .price {
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.03em;
    }

    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }
  }

  &.news_se {
    .nyheter_tagg {
      width: 4rem;
      height: 4rem;
      margin: -2.75rem 0.5rem 0 0;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url('../../svg/news_se.svg');

      ${theme.above.sm} {
        margin: -1.5rem 1rem 0 0;
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }

  &.news_eng {
    .nyheter_tagg {
      width: 4rem;
      height: 4rem;
      margin: -2.75rem 0.5rem 0 0;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url('../../svg/news_se.svg');

      ${theme.above.sm} {
        margin: -1.5rem 1rem 0 0;
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
`;

const NewsBadgeWrapper = styled('div')`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  z-index: 1;
  background: white;
  border-radius: 100%;
  height: 4.5rem;
  width: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.below.lg} {
    height: 3.2rem;
    width: 3.2rem;
    top: 0.5rem;
    right: 1rem;
  }
  span {
    font-family: ${theme.fonts.secondary};
    font-size: 1.2rem;
    ${theme.below.lg} {
      font-size: 0.9rem;
    }
  }
`;

const NewsBadge = () => {
  return (
    <NewsBadgeWrapper className="news-badge-wrapper">
      <span>{t('New')}!</span>
    </NewsBadgeWrapper>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = '457:640',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  children,
  secondImage,
  flyout,
  preImage,
  posterWall,
  isfavourites,
  ...linkProps
}) {
  const { isB2B } = useChannelCheck();
  const hasImages = product?.images && product?.images?.length > 0;
  const originalImage = product?.images?.[0]?.url;
  const landscape = product?.customFields?.find(
    ({ key }) => key === 'liggande'
  );
  const isLandscape = landscape?.boolValue;
  isLandscape ? (imageAspect = '640:457') : (imageAspect = '457:640');
  const [image, setImage] = useState(preImage || originalImage);
  const Tag = as;
  let badges = [...product.badges];

  const { selectedChannel } = useContext(ChannelContext);

  const newsBadge = product?.badges?.find(
    badge => badge.style === 'nyheter_tagg'
  );
  const hasNewsBadge = newsBadge?.name?.length > 0;
  const isCanvas = product?.customFields?.find(field => field.key === 'canvas')
    ?.boolValue;

  useEffect(() => {
    if (preImage) {
      setImage(preImage);
    } else {
      setImage(originalImage);
    }
  }, [preImage, originalImage]);

  const handleMouseOver = () => {
    if (product?.images?.length > 1) {
      if (image === product?.images?.[1]?.url) {
        setImage(originalImage);
      } else {
        setImage(product?.images?.[1]?.url);
      }
    }
  };

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  const getLanguage = () => {
    switch (selectedChannel.id) {
      case 2:
        return 'news_se';

      default:
        return 'news_eng';
    }
  };

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className, getLanguage())}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{
          margin: '0 0.5em 1em'
        }}
        onMouseEnter={() => handleMouseOver()}
        onMouseLeave={() => handleMouseOver()}
        onClick={flyout ? flyout.hideTarget : null}
      >
        <div
          className={cx(
            'image-wrapper',
            image !== originalImage && 'environment-image',
            isLandscape && 'landscape',
            isB2B && 'b2b',
            isCanvas && 'canvas'
          )}
        >
          {hasNewsBadge && <NewsBadge />}
          {hasImages ? (
            <Image
              cover={true}
              sizes={imageSizes}
              aspect={
                (isB2B && '4:5') || isfavourites || isCanvas
                  ? '4:5'
                  : posterWall
                  ? '4:5'
                  : image !== originalImage
                  ? '643:798'
                  : imageAspect
              }
              alt={product?.name}
              src={
                secondImage && hasImages && product?.images?.length > 1
                  ? product?.images?.[1]?.url
                  : image
              }
              modifiedDate={product?.images?.[1]?.modifiedDate}
            >
              {/* <Badges badges={badges} /> */}
            </Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </div>

        <section className="product-card-detail">
          <header>
            <h3>{product.name}</h3>
          </header>
          <div className="price-package-wrapper">
            {!posterWall && (
              <Price
                price={product.price}
                previousPrice={product.previousPrice}
                includeVat={isB2B ? false : true}
              />
            )}
            <Favourite
              product={product}
              style={{
                position: 'relative',
                padding: '0',
                fontSize: '1rem',
                top: '0',
                right: '0',
                marginLeft: '5px',
                marginTop: '-2px'
              }}
            />
          </div>
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
