import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import React, { useRef, useState } from 'react';
import { theme } from '../../Theme';
import CloseButton from '../../ui/CloseButton';
import MobileCategories from './Categories/MobileCategories';
import ChannelSelectorDropdown from './ChannelSelector/ChannelSelectorDropdown';
import useChannelCheck from '../../../utils/useChannelCheck';

const StyledCloseButton = styled(CloseButton)`
  margin-left: 0;
  margin-right: auto;
  path {
    fill: black;
  }
  &:focus {
    outline: none;
  }
`;

const Title = styled('h1')`
  text-align: center;
  position: absolute;
  font-size: 16px;
  color: #333;
`;

const NavBarHeader = styled('div')`
  position: relative;
  background: ${theme.colors.accent};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 50px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
`;

const InnerWrapper = styled('div')`
  height: 100%;
  background: ${theme.colors.lightAccent};
`;

export default function MobileMenu({ data }) {
  const { isB2B } = useChannelCheck();
  const [locked, setLocked] = useState(false);
  const [openCat, setOpenCat] = useState(null);
  const menuWrapper = useRef(null);
  if (!(data && data.categories)) return null;
  return (
    <>
      <DrawerTarget id="menu-drawer">
        {drawer => (
          <Drawer isOpen={drawer.isOpen}>
            <InnerWrapper locked={locked} ref={menuWrapper}>
              <NavBarHeader>
                <StyledCloseButton onClick={drawer.hideTarget} />
                <Title>{t('Menu')}</Title>
              </NavBarHeader>
              <Scroll>
                <MobileCategories
                  menuWrapper={menuWrapper}
                  locked={locked}
                  setLocked={setLocked}
                  categories={data.categories}
                  closeMenu={drawer.hideTarget}
                  openCat={openCat}
                  setOpenCat={setOpenCat}
                />
                {!isB2B && <ChannelSelectorDropdown />}
              </Scroll>
            </InnerWrapper>
          </Drawer>
        )}
      </DrawerTarget>
      {/* <ChannelSelectorFlyout /> */}
    </>
  );
}
