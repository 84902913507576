import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { useQuery } from '@apollo/react-hooks';
import headerCampaignQuery from './HeaderCampaignQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import { Link } from 'react-router-dom';
import useChannelCheck from '../../../utils/useChannelCheck';

const Wrapper = styled('div')``;
const Bar = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  ${theme.below.lg} {
    font-size: 12px;
  }
`;

const CampaignBar = ({
  background = '#d78f7f',
  color = 'white',
  children,
  link = '#'
}) => {
  return (
    <Bar to={link} style={{ backgroundColor: background, color: color }}>
      {children}
    </Bar>
  );
};

function HeaderCampaign(props) {
  const { isB2B } = useChannelCheck();
  const { data, loading, error } = useQuery(headerCampaignQuery, {
    variables: {
      id: isB2B ? 327 : 227
    },
    errorPolicy: 'ignore'
  });
  if (loading || error) return null;
  return data?.category?.content?.length > 0 ? (
    <Wrapper>
      <div className="header-campaign-inner">
        <Shortcodes
          content={data.category.content}
          components={{
            CampaignBar: CampaignBar
          }}
        />
      </div>
    </Wrapper>
  ) : null;
}

export default HeaderCampaign;
