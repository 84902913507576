import { styled } from 'linaria/react';
import React, { useState, useEffect } from 'react';
import { useIntl } from '@jetshop/intl';
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription';
import { theme } from '../Theme';
import { Helmet } from 'react-helmet-async';

const FieldErrorWrapper = styled('div')`
  h2 {
    font-family: ${theme.fonts.primary}!important;
  }
  &.footer {
    h2 {
      font-size: 1.2em !important;
      letter-spacing: 0 !important;
      text-align: right;
    }
    p {
      text-align: right;
      margin-right: 0px;
    }
    ${theme.below.lg} {
      h2,
      p {
        text-align: left;
      }
    }
  }
  ${theme.below.lg} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: 31rem;
  margin: 0 auto;
  // height: 2.5rem;
  justify-content: center;
  align-items: center;
  flex: 0 1 20rem;
  .newsletter-extras {
    display: none;
  }
  p {
    margin-right: 0px;
  }

  ${theme.above.lg} {
    margin-bottom: 2rem;
    margin-top: 1rem;
    p {
      margin-right: 10px;
    }
  }
  form,
  input {
    height: 80px;
    width: 100%;
    background: white;
    padding-right: 0 !important;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  input {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;
    border: 1px solid #bfbdbd;
    font-size: 12px;
    height: 100%;
  }
  button {
    margin-top: 10px;
    font-size: 14px;
    height: 100%;
    width: 100%;
    background: #eae6e1;
    color: black;
  }

  &.footer {
    form,
    input {
      height: 40px;
      flex-direction: row;
    }
    button {
      width: 80px;
      margin: 0;
    }
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

const NewsletterDisclaimer = styled('div')`
  p {
    font-size: 11px !important;
    padding: 0;
    margin: 0;
    font-style: italic;
    text-align: left;
    padding-top: 6px;
  }
`;

const NewsletterText = styled('div')`
  h2 {
    font-family: ${theme.fonts.primary}!important;
  }
  p {
    font-size: 0.95rem
    font-weight: 200;
    text-align: left;
    line-height: 1.4em;
    padding-bottom: 20px;
  }
`;

function NewsletterField({ title, text, kampanj, closeClick, footer }) {
  const {
    inputProps,
    submit,
    submitting,
    submitted,
    failed,
    errorStates: { alreadySubscribed, invalidEmail }
  } = useNewsletterSubscription({
    enabled: true
  });

  const t = useIntl();
  const disabled = inputProps.value.length === 0 || submitting;
  const [FormSubmitted, setFormSubmitted] = useState(false);

  const newsletter_submit = e => {
    e.preventDefault();

    window.grecaptcha
      .execute('6Lf8IUopAAAAAECnIud4dsQ5eARYNCe6Mboh_YPF', { action: 'submit' })
      .then(function(token) {
        submit(e);
      });
  };

  useEffect(() => {
    if (submitted) {
      setFormSubmitted(true);
    }
  }, [submitted]);

  return (
    <FieldErrorWrapper className={footer ? 'footer' : ''}>
      <>
        {FormSubmitted ? (
          <label htmlFor="newsletter">
            <h2>{t('Thanks for following us!')}</h2>
          </label>
        ) : (
          <label htmlFor="newsletter">
            <div
              dangerouslySetInnerHTML={{
                __html: title?.content
              }}
            ></div>
          </label>
        )}
      </>
      <Error>
        {alreadySubscribed &&
          t('You have already subscribed to the newsletter!')}
        {invalidEmail || failed
          ? t('Something went wrong. Please check your email and try again.')
          : null}
      </Error>
      <Wrapper className={footer ? 'footer' : ''}>
        {FormSubmitted ? (
          <>
            <NewsletterText
              dangerouslySetInnerHTML={{
                __html: kampanj?.content
              }}
            />
            {!footer && (
              <button
                onClick={() => {
                  closeClick();
                }}
                style={{ padding: '10px' }}
              >
                {t('Continue shopping')}
              </button>
            )}
          </>
        ) : (
          <>
            <Helmet>
              <script src="https://www.google.com/recaptcha/api.js?render=6Lf8IUopAAAAAECnIud4dsQ5eARYNCe6Mboh_YPF"></script>
            </Helmet>
            <NewsletterText>
              <div
                dangerouslySetInnerHTML={{
                  __html: text?.content
                }}
              ></div>
            </NewsletterText>
            <form
              onSubmit={newsletter_submit}
              disabled={disabled}
              id="footer-newsletter"
            >
              <input
                type="email"
                id="newsletter"
                placeholder={t('Enter email address')}
                style={{ paddingRight: submitting ? '8rem' : '4rem' }}
                data-testid="newsletter-subscription-input"
                {...inputProps}
              />
              <button
                type="submit"
                disabled={disabled}
                data-testid="newsletter-subscription-submit"
              >
                {submitting ? t('Submitting...') : t('Submit')}
              </button>
            </form>
            <NewsletterDisclaimer>
              <p>
                {t(
                  'By entering your email, you agree to receive personal messages and marketing from us.'
                )}
              </p>
            </NewsletterDisclaimer>
          </>
        )}
      </Wrapper>
    </FieldErrorWrapper>
  );
}

export default NewsletterField;
