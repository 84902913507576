import { styled } from 'linaria/react';
import React from 'react';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50%;
  line-height: 20px;
  background-color: #333;
  overflow: hidden;

  > div {
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    color: white;
  }

  span {
    height: 20px;
    width: 20px;
    border: 0;
    border-radius: 50%;
    font-size: 0.5rem;
    line-height: 20px;
    color: white;
    background-color: #333;
    overflow: hidden;
  }
`;

const Badge = ({ text }) => {
  return (
    <Wrapper className="badge">
      <div>{text}</div>
    </Wrapper>
  );
};

export default Badge;
