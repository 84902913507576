function getFullName(product) {
  if (product?.variant?.values) {
    return product.product.name + ' - ' + product?.variant?.values[0];
  }
  return product.product.name;
}

export function useTriggerbeeProductTracking({ products }) {
  // var list = [{
  //   name: "Product Name",
  //   category: "Product category or brand",
  //   size: "38",
  //   price: 299,
  //   quantity: 1,
  //   image: "/images/productimage.jpg",
  //   link: "/category/produktX"
  // }];

  var list = products.map(product => ({
    name: getFullName(product),
    price: product.total.incVat,
    quantity: product.quantity,
    image: product?.product?.images[0]?.url || null,
    link: `/https://designedposters.com${product.product.primaryRoute.path}`
  }));

  if (typeof window !== 'undefined' && window.mtr_custom && window.mtr) {
    window.mtr_custom.session = { products: JSON.stringify(list) };
    window.mtr.event('Logged products');
  } else {
    console.log('Triggerbee tracking not available');
  }
}

export function trackTriggerbee({ products }) {
  // var list = [{
  //   name: "Product Name",
  //   category: "Product category or brand",
  //   size: "38",
  //   price: 299,
  //   quantity: 1,
  //   image: "/images/productimage.jpg",
  //   link: "/category/produktX"
  // }];

  var list = products.map(product => ({
    name: getFullName(product),
    price: product.total.incVat,
    quantity: product.quantity,
    image: product?.product?.images[0]?.url || null,
    link: `/https://designedposters.com${product.product.primaryRoute.path}`
  }));

  if (typeof window !== 'undefined' && typeof window.mtr !== 'undefined') {
    window.mtr_custom.session = { products: JSON.stringify(list) };
    window.mtr.event('Logged products');
    console.log('Triggerbee tracked:', list);
  } else {
    console.log('Triggerbee NOT tracked:', list);
  }
}
