import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { smallSection } from './UI/Form';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';

import { theme } from '../Theme';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  width: 100%;
  color: white;
  font-weight: bold;
  background-color: ${theme.colors.green};
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.green};
  font-size: 0.875em;
  display: block;
  text-align: center;
  margin: 10px 0 0 0;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
  }
`;

export const FormHeader = styled('header')`
  text-align: center;
  h1 {
    font-size: 3rem;
  }
`;

const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

function LogInPage() {
  const { routes } = useShopConfig();
  return (
    <LogInPageMaxWidth>
      <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
      <LogInFormProvider redirect="my-pages">
        {({ globalError, isSubmitting, isValid }) => (
          <section className={cx(smallSection)}>
            <FormHeader>
              <h1>{t('Login')}</h1>
            </FormHeader>
            <Input type="email" name="email" label={t('E-mail address')} />
            <Input type="password" name="password" label={t('Password')} />

            {globalError && (
              <GlobalError style={{ marginBottom: '2em' }}>
                {globalError}
              </GlobalError>
            )}

            <section className={actionWrapper}>
              <StyledTrendButton
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                loadingText={t('Hold on a moment...')}
              >
                {t('Log in')}
              </StyledTrendButton>
              <TextLink to={routes.forgotPassword.path}>
                {t('Forgot password?')}
              </TextLink>
              <TextLink to={routes.signup.path} className="signup-link">
                {t('Not yet a member? Sign Up')}
              </TextLink>
            </section>
          </section>
        )}
      </LogInFormProvider>
    </LogInPageMaxWidth>
  );
}

export default LogInPage;
