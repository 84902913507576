import { useProductList } from '@jetshop/core/hooks/ProductList';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import React from 'react';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';
import { theme } from '../Theme';
import Badge from '../ui/Badge';

const FavoritesLink = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  margin-left: 20px;
  height: 25px;

  &:focus {
    outline: none;
  }

  ${theme.below.lg} {
    margin-left: 10px;
  }
  svg {
    width: 25px !important;
    height: 25px !important;
    display: block;
    fill: transparent;
  }
  .badge {
    position: absolute;
    top: -4px;
    right: -14px;
    background: black;
  }
`;

export function FavouriteCount({ className }) {
  const { count } = useProductList();

  return (
    <DrawerTrigger preventOverflow={true} id="favourites-drawer">
      {drawer => (
        <FavoritesLink
          target={drawer}
          onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
          className={className}
        >
          {count > 0 && <Badge text={count} />}
          <HeartSVG />
        </FavoritesLink>
      )}
    </DrawerTrigger>
  );
}
