import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
import AnimateHeight from 'react-animate-height';
import Image from '@jetshop/ui/Image';
import MenuImageQuery from './MenuImageQuery.gql';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Shortcodes } from '@jetshop/flight-shortcodes';

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  flex-direction: row;
  flex-wrap: wrap;

  width: auto;
  flex: initial;

  margin: 2rem 0;
  padding: 0 1.5rem;

  width: 100%;
  display: flex;

  > div {
    display: inline-block;
    border-left: 1px solid #ddd !important;
    padding-left: 10px;

    width: auto;
    min-width: 170px;
  }

  a {
    color: ${theme.colors.black};
    transition: all ease 0.3s;
    :hover {
      text-decoration: underline;
    }
    &.active {
      font-weight: 600;
      text-decoration: underline;
    }
  }
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  border: 0;
  width: 100%;
  padding: 0;
  margin: auto;
`;

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 10;
  will-change: height;
  transition: height 0.3s ease-in-out;

  border-top: 1px solid #ececec;

  h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1em;
  }
`;

const NoThirdTierItems = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 0.05rem 1rem;
  width: auto;
  margin-bottom: 1rem;
  h2 {
    padding: 0;

    font-size: inherit;
    font-weight: 600;
    line-height: 1.5;
    svg {
      width: 18px !important;
      height: 18px !important;
      vertical-align: middle;
      margin-top: -2px;
    }
    a {
      transition: all ease 0.3s;
      &.active {
        color: ${theme.colors.accent};
        font-weight: 600;
      }
    }
  }

  &.active-image.active-image {
    border: 0 !important;

    a {
      text-decoration: none !important;
      &:hover {
        text-decoration: underline !important;
        h2 {
          transform: translateY(-3px);
        }
      }
    }
    h2 {
      margin-top: 8px;
      transition: all, 0.2s ease;
    }
  }
`;

const MenuImage = styled('div')`
  flex: 1;
  padding: 20px;
  max-width: 320px;
  > div {
    display: flex;
    height: 100%;
    align-items: center;
  }
`;

const MenuImageContent = styled('div')`
  text-align: center;
  width: 100%;
  h3 {
    color: #333;
    font-size: 1rem;
    line-height: 1;
    margin: 15px auto 5px;
    padding: 0;
  }
  h2 {
    color: #333;
    font-size: 1.2rem;
    line-height: 1;
    margin: 0 0 10px 0;
    padding: 0;
    padding-top: 5px;
  }
`;

const Menybild = ({ image, link, children }) => (
  <MenuImageContent>
    <Link to={link}>
      <Image src={image} cover aspect={'680:450'} alt={image} />
      {children}
    </Link>
  </MenuImageContent>
);

const MenuImageItem = ({ closeNav, id }) => {
  return (
    <Query query={MenuImageQuery} variables={{ id: id }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error!: ${error}`;
        return data.category ? (
          <div onClick={closeNav}>
            <Shortcodes
              content={data.category.content}
              components={{
                Menybild: Menybild
              }}
            />
          </div>
        ) : null;
      }}
    </Query>
  );
};

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <ContentFit>
          <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
            <MaxWidth>
              {activeCategory &&
                activeCategory.subcategories.map((cat, index) =>
                  cat.subcategories.length > 0 ? (
                    <SubMenuSection
                      key={cat.id}
                      heading={cat}
                      categories={cat.subcategories}
                      onSelect={closeNav}
                    />
                  ) : (
                    <React.Fragment key={index}>
                      {cat.name !== 'Menybild' ? (
                        <NoThirdTierItems
                          className={
                            cat?.images?.length ? 'active-image' : 'no-image'
                          }
                          key={index}
                        >
                          <CategoryLink category={cat} onClick={closeNav}>
                            {cat?.images?.length > 0 && (
                              <Image
                                src={cat.images[4].url}
                                cover
                                sizes={'200px'}
                                aspect={'1:1'}
                              />
                            )}
                            <h2>{cat.name}</h2>
                          </CategoryLink>
                        </NoThirdTierItems>
                      ) : null}
                    </React.Fragment>
                  )
                )}
            </MaxWidth>
            {activeCategory &&
              activeCategory.subcategories.map((cat, index) =>
                cat.name === 'Menybild' ? (
                  <MenuImage key={index}>
                    <MenuImageItem closeNav={closeNav} id={cat.id} />
                  </MenuImage>
                ) : null
              )}
          </AnimateHeight>
        </ContentFit>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SubMenuWrapper;
