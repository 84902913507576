import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const useChannelCheck = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const isB2B = selectedChannel?.id === 3;

  return { selectedChannel, isB2B };
};

export default useChannelCheck;
