import React, { useContext, useState } from 'react';
import { styled } from 'linaria/react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Selector from './Selector';
import { theme } from '../../../Theme';

const Container = styled.div`
  position: relative;
  height: 100%;
  margin-left: auto;
  ${theme.below.lg} {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const ChannelButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;

  ${theme.below.lg} {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }

  img {
    height: 16px;
    margin-right: 12px;
    margin-top: -2px;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 23px;
  right: -20px;
  width: 180px;
  z-index: 4;
  ${theme.below.lg} {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    z-index: unset;
    > div {
      max-width: 100%;
      box-shadow: none;
    }
  }
`;

const ChannelSelectorModal = props => {
  const [open, setOpen] = useState(false);
  const { selectedChannel, channels, updateChannel } = useContext(
    ChannelContext
  );

  return (
    <Container>
      <ChannelButton {...props} onClick={() => setOpen(!open)}>
        <img
          src={`https://countryflags.jetshop.io/${selectedChannel.country.code}/flat/32.png`}
          alt="Country Flag"
        />
        <span>
          <span>
            {selectedChannel?.country?.name} / {selectedChannel?.currency?.id}
          </span>
        </span>
      </ChannelButton>

      {open && (
        <div>
          <Dropdown>
            <Selector
              channels={channels}
              selectedChannel={selectedChannel}
              updateChannel={updateChannel}
              hideTitle={true}
              {...props}
            />
          </Dropdown>
        </div>
      )}
    </Container>
  );
};
export default ChannelSelectorModal;
