import { Intl } from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import SearchField from './SearchField';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { theme } from '../../../Theme';

const InnerSearchBar = styled('div')`
  width: 100%;
  max-width: 200px;

  ${theme.below.lg} {
    max-width: 100%;
    margin-bottom: -8px;
    margin-top: 10px;
  }

  svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: auto;
    z-index: 1;
    width: 20px !important;
    height: 20px !important;
  }
`;

const StyledSearchField = styled(SearchField)`
  justify-content: flex-start;
  width: 100%;
  max-width: 200px;
  margin: 0;
  ${theme.below.lg} {
    max-width: 100%;
  }

  ${SearchField.Wrapper} {
    ${theme.below.lg} {
      width: 100%;
    }
    input {
      border: 0;
      font-size: 11px;
      background: ${theme.colors.lightAccent};
      height: 40px;
      width: 100%;
      padding: 0 20px;
      ${theme.below.lg} {
        font-size: 16px;
        border-bottom: 1px solid #ddd;
      }
      &:focus {
        background: #f7f7f7;
        outline: none;
      }
    }
  }
  ${SearchField.Cancel} {
    display: none;
  }
`;

const SearchBar = setMobileSearchOpen => (
  <InnerSearchBar>
    <Intl>
      {/* This is necessary to use t for the placeholder string */}
      {t => (
        <StyledSearchField
          autocompleteQuery={AutocompleteQuery}
          onCancel={() => null}
          placeholder={t('Search')}
          setMobileSearchOpen={setMobileSearchOpen}
        />
      )}
    </Intl>
  </InnerSearchBar>
);

export { SearchBar };
