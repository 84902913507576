import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';

const Wrapper = styled('div')`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 7px;
  }
`;

const MainMenuItem = styled('li')`
  a {
    padding: 40px 25px;
    display: inline-block;
    color: #333;
    text-decoration: none;
    position: relative;
    letter-spacing: 1px;

    &:after {
      content: '';
      background: black;
      position: absolute;
      bottom: 15px;
      left: 25px;
      right: 25px;
      transition: all, 0.2s ease;
      height: 0;
    }

    &:hover {
      &:after {
        height: 3px;
      }
    }
    &.active {
      &:after {
        height: 3px;
      }
    }
  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => (
                  <CategoryMenuItem key={cat.id} cat={cat} props={props} />
                ))
              : null}
          </ul>
          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, props }) => {
  const excludedCategories = [150, 151, 152];
  if (excludedCategories.includes(cat.id)) {
    return null;
  }
  return (
    <MainMenuItem
      // When mousing over a menu item, set it as the active nav
      onMouseEnter={() => props.setActiveCategory(cat)}
    >
      <CategoryLink
        // When following a category link, close the menu
        onClick={props.clearActiveCategories}
        category={cat}
        className="toplevel"
      >
        {cat.name}
      </CategoryLink>
    </MainMenuItem>
  );
};

export default CategoryMenuContainer;
