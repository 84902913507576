import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import { TrendButton } from '@jetshop/ui/Button';
import { css } from 'linaria';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';
import { SmallCaps } from '../../ui/Headings';
import { theme } from '../../Theme';

const sectionStyle = css`
  margin-top: 2em;
  margin-bottom: 2em;
`;

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  width: 100%;
  color: white;
  font-weight: bold;
  background-color: ${theme.colors.green};
`;

export default function LoginFields() {
  const { fields } = useLoginFields();
  const { globalError, isSubmitting } = useSignupForm();

  return (
    <section className={sectionStyle}>
      <SmallCaps className="heading">{t('Email & Password')}</SmallCaps>

      {globalError && (
        <GlobalError style={{ marginBottom: '2em' }}>{globalError}</GlobalError>
      )}

      {fields.map(field => {
        return <Input {...field.inputProps} />;
      })}

      <StyledTrendButton type="submit" disabled={isSubmitting}>
        {isSubmitting ? t('Hold on a moment…') : t('Create Profile')}
      </StyledTrendButton>
    </section>
  );
}
